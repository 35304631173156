// styles.js
import styled from "styled-components";

export const Nav = styled.nav`
	background: white;
	color: black;
	padding: 3px;
	display: flex;
	justify-content: space-between; // Aligns children to the extremes
	position: fixed; // This will make the nav fixed
	top: 0; // Align to the top of the viewport
	left: 0; // Align to the left of the viewport
	width: 100%; // Nav will span the full width of the viewport
	max-width: 100%;
	height: 65px;
	z-index: 1000; // Ensure the nav stays on top of other content
`;

export const NavList = styled.ul`
	list-style-type: none;
	display: flex;
`;

export const NavItem = styled.li`
	padding: 1px 15px 1px 5px;
`;

// Container for grouping items
export const NavGroup = styled.div`
	display: flex;

	&:first-of-type {
		// Targets the first NavGroup
		margin-left: -15px; // Adds additional padding to the left: ;
	}
`;

export const NavImage = styled.img`
	height: 40px; // Adjust the size as needed
	width: auto;
	// Add any other styles you need
`;
