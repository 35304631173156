// src/components/MapComponent/styles.js
import styled from "styled-components";

export const MenuMapContainer = styled.div`
	display: flex;
`;

export const DesktopMenuContainer = styled.div`
	display: none; // Default to not display

	@media (min-width: 768px) {
		display: block;
		width: 25%;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		overflow-y: auto;
		z-index: 200;
	}
`;

export const MapContainer = styled.div`
	overflow: hidden; // This will contain the zoomed map within the bounds of the MapContainer
	position: relative;
	width: 100vw; // Set this to control the map size
	text-align: center; // Center the map image if the container is wider
	/* height: 80%; */
	margin-top: 80px;

	@media (min-width: 768px) {
		height: 100%; // Full height
		margin-top: 0; // No margin at the top
		margin-left: 25%; // Offset by the width of the menu
	}
`;

export const StyledMap = styled.img`
	width: 100%; // This will make the image responsive
	height: 100%;
	cursor: pointer; // To indicate the map is interactive
	transition: transform 0.3s ease; // Smooth zoom transition
	// This transform will be dynamically set via props
	transform: ${(props) => `scale(${props.zoomLevel})`};
	transform-origin: center center;

	&:active {
		cursor: grabbing;
	}
`;

export const SvgOverlay = styled.svg`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

export const ClickableArea = styled.path`
	fill: transparent;
	stroke: transparent;
	stroke-width: 5;

	cursor: pointer;
	&:hover {
		fill: rgba(255, 69, 0, 0.5);
	}
`;

export const BuildingContainer = styled.div`
	position: absolute;
	top: ${({ top }) => top};
	left: ${({ left }) => left};
	width: ${({ width }) =>
		width || "5rem"}; // Default width if not specified
	height: ${({ height }) =>
		height || "4rem"}; // Default height if not specified
	cursor: pointer;
	/* border: 2px solid red; */
`;

// Add more styled components as necessary for interactive elements or overlays
