import styled from "styled-components";

export const MapFooterContainer = styled.div`
	background-color: #551a3d; // The color from your image
	z-index: 100;
	color: white;
	text-align: center;
	padding: 10px; // Adjust the padding as needed
	position: fixed;
	bottom: 0;
	right: 0;
	width: 75%;
	max-width: 75%;
	box-sizing: border-box; // Ensures padding is included in the width
	height: 13.5vh;
	border-top: 3px solid red;

	@media (max-width: 768px) {
		width: 100%; // Full width on smaller screens
		max-width: 100%;
		height: 15vh;
		right: 0; // Ensure it aligns to the right edge
	}
`;

export const InstructionText = styled.p`
	margin: 0; // Removes default margin
	font-size: 16px; // Adjust font size as needed
	line-height: 1.5; // Adjust line height for better readability
`;
