import React from 'react';
import { ZoomControlsContainer, ZoomButton } from './styles';

const ZoomControls = ({ onZoomIn, onZoomOut }) => {
  return (
    <ZoomControlsContainer>
      <ZoomButton onClick={onZoomIn}>+</ZoomButton>
      <ZoomButton onClick={onZoomOut}>-</ZoomButton>
  </ZoomControlsContainer>
  );
};

export default ZoomControls;