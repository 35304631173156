import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import mapsData from "../mapData";
import {
	MapContainer,
	StyledMap,
	BuildingContainer,
	MenuMapContainer,
	DesktopMenuContainer,
	ClickableArea,
	SvgOverlay,
} from "./styles";
import BuildingModal from "../BuildingModal";
import MapFooterComponent from "../MapFooterComponent";
import ZoomControlsComponent from "../ZoomControlsComponent";
import MobileMenu from "../MobileMenu";

const MIN_ZOOM = 1; // minimum zoom level
const MAX_ZOOM = 2; // maximum zoom level

const MapComponent = () => {
	const [showFirstMap, setShowFirstMap] = useState(true);
	// const [showSecondMap, setShowSecondMap] = useState(true);

	const [showModal, setShowModal] = useState(false);

	const [currentMap, setCurrentMap] = useState(null);
	const { mapId } = useParams();

	const [mapSrc, setMapSrc] = useState(
		"/images/main-map-building-codes.png"
	); // Default to mobile map

	const [zoomLevel, setZoomLevel] = useState(1); // Start with no zoom

	const [mapOffset, setMapOffset] = useState({ x: 0, y: 0 });

	const [currentMapSelection, setCurrentMapSelection] = useState(null);

	const [currentModalContent, setCurrentModalContent] = useState({});

	const [currentSecondMapArea, setCurrentSecondMapArea] = useState(null);

	const handleStart = (event) => {
		// Use `touches` for touch events, `clientX/Y` for mouse events
		const point = event.touches ? event.touches[0] : event;
		const startX = point.clientX - mapOffset.x;
		const startY = point.clientY - mapOffset.y;

		const handleMove = (moveEvent) => {
			const pointMove = moveEvent.touches
				? moveEvent.touches[0]
				: moveEvent;
			const newX = pointMove.clientX - startX;
			const newY = pointMove.clientY - startY;
			setMapOffset({ x: newX, y: newY });
		};

		const handleEnd = () => {
			document.removeEventListener("mousemove", handleMove);
			document.removeEventListener("mouseup", handleEnd);
			document.removeEventListener("touchmove", handleMove);
			document.removeEventListener("touchend", handleEnd);
		};

		document.addEventListener("mousemove", handleMove);
		document.addEventListener("mouseup", handleEnd);
		document.addEventListener("touchmove", handleMove);
		document.addEventListener("touchend", handleEnd);
	};

	// Function to handle zooming in
	const handleZoomIn = () => {
		setZoomLevel((prevZoomLevel) => {
			const newZoomLevel = Math.min(prevZoomLevel * 1.1, MAX_ZOOM); // 3 is the max zoom level
			// setBuildingPosition(calculateNewPosition(newZoomLevel));
			return newZoomLevel;
		});
	};

	// Function to handle zooming out
	const handleZoomOut = () => {
		setZoomLevel((prevZoomLevel) => {
			const newZoomLevel = Math.max(prevZoomLevel / 1.1, MIN_ZOOM); // 0.5 is the min zoom level
			// setBuildingPosition(calculateNewPosition(newZoomLevel));
			return newZoomLevel;
		});
	};

	const handleBuildingClick = (areaId) => {
		const currentMapData = mapsData.find((m) => m.id === mapId);
		// This is the info we will use in the modal
		const currentModalData = currentMapData.areas[areaId];

		// Check if we have target map data for the clicked area
		if (
			currentMapData &&
			currentMapData.areas &&
			currentMapData.areas[areaId]
		) {
			const targetMap = currentMapData.areas[areaId].targetMap;

			// Check if the second map for this area is already being displayed
			if (!showFirstMap && currentSecondMapArea === areaId) {
				// Set modal content to be currentModalData
				setCurrentModalContent(currentModalData);
				setShowModal(true); // Show the modal
			} else {
				setMapSrc(targetMap); // Change to the second map
				setShowFirstMap(false);
				setShowModal(false);
				setCurrentSecondMapArea(areaId); // Record this area as the current second map
			}
		} else {
			setCurrentModalContent(currentModalData);
			setShowModal(true); // Show the modal if no specific map is set for the area
		}
	};

	const buildingContainers =
		currentMap &&
		currentMap.areas &&
		Object.entries(currentMap.areas).map(([areaId, areaData]) => (
			<BuildingContainer
				key={areaId}
				top={areaData.top}
				left={areaData.left}
				width={areaData.width} // Optional
				height={areaData.height} // Optional
				onClick={() => handleBuildingClick(areaId)}
			/>
		));

	useEffect(() => {
		const selectedMap = mapsData.find((m) => m.id === mapId);
		setCurrentMap(selectedMap);
		setShowFirstMap(true); // Reset to the first map when the mapId changes
		setCurrentMapSelection(mapId); // Set the current map selection
		setShowModal(false); // Close the modal when the mapId changes

		setCurrentSecondMapArea(null);

		const handleResize = () => {
			if (window.innerWidth >= 768) {
				// Desktop
				setMapSrc(
					selectedMap.desktopImagePath ||
						selectedMap.imagePath
				);
			} else {
				// Mobile
				setMapSrc(selectedMap.imagePath);
			}
		};

		handleResize(); // Call once initially to set the map
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [mapId, currentMapSelection]);

	if (!currentMap) return null;

	return (
		<MenuMapContainer style={{ overflow: "hidden" }}>
			<DesktopMenuContainer>
				<MobileMenu />
			</DesktopMenuContainer>
			<MapContainer
				style={{
					transform: `scale(${zoomLevel})`,
					transformOrigin: "center center",
				}}
				// onMouseDown={handleStart}
			>
				<StyledMap
					src={currentMap.imagePath}
					alt={currentMap.mapName}
				/>
				<SvgOverlay
					viewBox="0 0 7394 12130"
					zoomLevel={zoomLevel}
					mapData={mapsData}
				>
					{currentMap &&
						currentMap.areas &&
						Object.entries(currentMap.areas).map(
							([areaId, area]) => (
								<ClickableArea
									key={areaId}
									d={area.pathData}
									onClick={() =>
										handleBuildingClick(
											areaId
										)
									}
								/>
							)
						)}
				</SvgOverlay>
				{showModal && (
					<BuildingModal
						isOpen={showModal}
						onClose={() => setShowModal(false)}
						modalContent={currentModalContent}
						zoomLevel={1 / zoomLevel} // Pass the inverse of the current zoom level
					/>
				)}
			</MapContainer>
			<ZoomControlsComponent
				onZoomIn={handleZoomIn}
				onZoomOut={handleZoomOut}
			/>
			<MapFooterComponent />
		</MenuMapContainer>
	);
};

export default MapComponent;
