import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavComponent from './components/navComponent';
import MobileMenu from './components/MobileMenu';
import MapComponent from './components/MapComponent';
import styled from 'styled-components';

const LayoutContainer = styled.div`
	display: flex;
	flex-direction: column; // Stacks components vertically on mobile

	@media (min-width: 768px) {
		// Adjust for tablet/desktop screens
		flex-direction: row; // Places components side by side for larger screens
	}
`;

function App() {
	return (
		<Router>
			<div>
				<NavComponent />
				<LayoutContainer>
					<Routes>
						<Route
							path='/map/:mapId'
							element={<MapComponent />}
						/>
						<Route
							path='/'
							element={<MobileMenu />}
						/>
					</Routes>
				</LayoutContainer>
			</div>
		</Router>
	);
}

export default App;

// import React, { useState, useEffect } from 'react';
// import './App.css';
// import {
// 	BrowserRouter as Router,
// 	Routes,
// 	Route,
// 	useNavigate,
// } from 'react-router-dom';
// import NavComponent from './components/navComponent';
// import MobileMenu from './components/MobileMenu';
// import MapComponent from './components/MapComponent';
// import styled from 'styled-components';

// const LayoutContainer = styled.div`
// 	display: flex;
// 	flex-direction: column;

// 	@media (min-width: 768px) {
// 		flex-direction: row;
// 	}
// `;

// function App() {
// 	const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

// 	useEffect(() => {
// 		const handleResize = () => {
// 			setIsDesktop(window.innerWidth >= 768);
// 		};

// 		window.addEventListener('resize', handleResize);
// 		return () => window.removeEventListener('resize', handleResize);
// 	}, []);

// 	return (
// 		<Router>
// 			<div>
// 				<NavComponent />
// 				<LayoutContainer>
// 					<Routes>
// 						<Route
// 							path='/'
// 							element={
// 								isDesktop ? (
// 									<MapComponent />
// 								) : (
// 									<MobileMenu />
// 								)
// 							}
// 						/>
// 						<Route
// 							path='/map/:mapId'
// 							element={<MapComponent />}
// 						/>
// 					</Routes>
// 				</LayoutContainer>
// 			</div>
// 		</Router>
// 	);
// }

// export default App;
