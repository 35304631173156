import React, { useEffect, useState, modalRef } from "react";
import {
	ModalBackdrop,
	ModalContainer,
	ModalHeader,
	CloseButton,
	ModalImage,
	TabContainer,
	Tab,
	Title,
	TopHeader,
	BottomHeader,
	ModalBackContainer,
} from "./styles.js";
import InformationComponent from "../InformationComponent/index.js";
import AmenitiesComponent from "../AmenitiesComponent/index.js";

const BuildingModal = ({ isOpen, onClose, modalContent, zoomLevel }) => {
	const [activeTab, setActiveTab] = useState("information");

	console.log(modalContent);

	if (!isOpen) return null;

	return (
		<ModalBackdrop onClick={onClose}>
			<ModalContainer
				style={{
					transform: `translate(-50%, -50%) scale(${zoomLevel})`,
					transformOrigin: "center center",
				}}
				onClick={(e) => e.stopPropagation()}
			>
				<ModalHeader>
					<TopHeader>
						<CloseButton onClick={onClose}>
							X
						</CloseButton>
					</TopHeader>
					<BottomHeader>
						<Title>{modalContent?.title}</Title>
					</BottomHeader>
				</ModalHeader>
				<ModalImage
					src={modalContent?.informationItemImagePath}
					alt="Modal Image"
				/>
				<TabContainer>
					<Tab
						className={
							activeTab === "information"
								? "active"
								: ""
						}
						onClick={() => setActiveTab("information")}
					>
						Information
					</Tab>
					<Tab
						className={
							activeTab === "amenities"
								? "active"
								: ""
						}
						onClick={() => setActiveTab("amenities")}
					>
						Amenities
					</Tab>
				</TabContainer>
				{activeTab === "information" &&
					modalContent?.information && (
						<InformationComponent
							style={{}}
							data={modalContent?.information}
						/>
					)}
				{activeTab === "amenities" &&
					modalContent?.information && (
						<AmenitiesComponent
							// data={modalContent?.information}
							data={modalContent?.amenities}
						/>
					)}
			</ModalContainer>
		</ModalBackdrop>
	);
};

export default BuildingModal;
