import React from "react";
import {
	InfoSection,
	SubTitle,
	AddressLine1,
	AddressLine2,
	AddressLine3,
	Hours,
	ContactInfoLine1,
	ContactInfoLine2,
	ContactInfoLine3,
	ScrollableContent,
	ModalInfoIcon,
	ModalInfoHeaderName,
	ModalInfoDescription,
	ModalInfoDescriptionLine2,
} from "./styles";

const InformationComponent = ({ data }) => {
	console.log(data);
	return (
		<>
			<InfoSection>
				{/* <ScrollableContent> */}
				{data &&
					data.map((item, index) => (
						<div key={index}>
							{item.infoComponentData
								.iconImagePath && (
								<ModalInfoIcon
									src={
										item
											.infoComponentData
											.iconImagePath
									}
									alt="Info Icon"
								/>
							)}
							{item.infoComponentData
								.headerName && (
								<ModalInfoHeaderName>
									{
										item
											.infoComponentData
											.headerName
									}
								</ModalInfoHeaderName>
							)}
							{item.infoComponentData
								.description && (
								<ModalInfoDescription>
									{
										item
											.infoComponentData
											.description
									}
								</ModalInfoDescription>
							)}
							{item.infoComponentData
								.descriptionLine2 && (
								<ModalInfoDescriptionLine2>
									{
										item
											.infoComponentData
											.descriptionLine2
									}
								</ModalInfoDescriptionLine2>
							)}
							{item.infoComponentData.subTitle && (
								<SubTitle>
									{
										item
											.infoComponentData
											.subTitle
									}
								</SubTitle>
							)}
							{item.infoComponentData
								.addressLine1 && (
								<AddressLine1>
									{
										item
											.infoComponentData
											.addressLine1
									}
								</AddressLine1>
							)}
							{item.infoComponentData
								.addressLine2 && (
								<AddressLine2>
									{
										item
											.infoComponentData
											.addressLine2
									}
								</AddressLine2>
							)}
							{item.infoComponentData
								.addressLine3 && (
								<AddressLine3>
									{
										item
											.infoComponentData
											.addressLine3
									}
								</AddressLine3>
							)}
							{item.infoComponentData.hours && (
								<Hours>
									{
										item
											.infoComponentData
											.hours
									}
								</Hours>
							)}
							{item.infoComponentData
								.contactInfoLine1 && (
								<ContactInfoLine1>
									{
										item
											.infoComponentData
											.contactInfoLine1
									}
								</ContactInfoLine1>
							)}
							{item.infoComponentData
								.contactInfoLine2 && (
								<ContactInfoLine2>
									{
										item
											.infoComponentData
											.contactInfoLine2
									}
								</ContactInfoLine2>
							)}
							{item.infoComponentData
								.contactInfoLine3 && (
								<ContactInfoLine3>
									{
										item
											.infoComponentData
											.contactInfoLine3
									}
								</ContactInfoLine3>
							)}
						</div>
					))}
				{/* </ScrollableContent> */}
			</InfoSection>
		</>
	);
};

export default InformationComponent;
