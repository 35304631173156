import styled from "styled-components";

export const ZoomControlsContainer = styled.div`
	/* position: absolute; */
	position: fixed; // Use fixed to keep it in view
	top: 130px; // Adjust this value to position the buttons
	right: 10px; // Adjust this value to position the buttons
	display: flex;
	flex-direction: column;
	padding: 5px; // Optional: for styling
	z-index: 1;
`;

export const ZoomButton = styled.button`
	background-color: #551a3d; // The dark background color
	color: white; // The color of the plus and minus symbols
	border: none; // Remove the border
	border-radius: 4px; // Rounded corners
	padding: 10px; // Spacing inside the buttons
	margin-bottom: 5px; // Space between buttons
	font-size: 36px; // Size of the plus and minus symbols
	font-weight: bold; // Make the plus and minus symbols thicker
	cursor: pointer; // Cursor to pointer to indicate it's clickable
	display: flex; // Center the content
	align-items: center; // Center the content
	justify-content: center; // Center the content
	height: 40px; // Specific height for the buttons
	width: 40px; // Specific width for the buttons

	&:hover {
		opacity: 0.9; // Slight opacity change on hover for feedback
	}

	&:last-child {
		margin-bottom: 0; // No margin on the last button
	}
`;
