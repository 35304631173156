import styled from "styled-components";

export const InfoSection = styled.section`
	padding: 16px 8px 16px 16px;
	background: #f9f9f9; // Use a light grey background
	font-family: "Arial", sans-serif; // Replace with the font you're using
	text-align: left; // Aligns all text to the left
	max-height: 200px; // Adjust this value to fit the modal size
	overflow-y: scroll;

	// Custom scrollbar styles for Webkit browsers
	&::-webkit-scrollbar {
		width: 6px; // Thinner scrollbar
	}

	&::-webkit-scrollbar-track {
		background: #f1f1f1; // Color of the scrollbar track
	}

	&::-webkit-scrollbar-thumb {
		background: #551a3d; // Custom scrollbar thumb color
		border-radius: 5px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #3a0d29; // Darker color on hover
	}

	/* Firefox scrollbar styles */
	& {
		scrollbar-width: thin;
		scrollbar-color: #551a3d #f1f1f1;
	}
`;

export const ScrollableContent = styled.div`
	max-height: 300px; // Adjust this value as needed
	overflow-y: scroll; // Enables scrolling for this section
	/* padding-right: 3px; // Adjust padding to create visual space for the scrollbar */

	// Custom scrollbar styles for Webkit browsers
	&::-webkit-scrollbar {
		width: 6px; // Thinner scrollbar
	}

	&::-webkit-scrollbar-track {
		background: #f1f1f1; // Color of the scrollbar track
	}

	&::-webkit-scrollbar-thumb {
		background: #551a3d; // Custom scrollbar thumb color
		border-radius: 5px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: #3a0d29; // Darker color on hover
	}

	/* Firefox scrollbar styles */
	& {
		scrollbar-width: thin;
		scrollbar-color: #551a3d #f1f1f1;
	}
`;

export const SubTitle = styled.h2`
	color: #739fb6; // Subtitles are black
	font-size: 18px; // Adjust the font size as needed
	margin: 16px 0 24px 0;
	font-weight: bold; // Subtitles appear bold
`;

export const AddressLine1 = styled.p`
	color: #333;
	margin: 0 0 1px 0;
`;

export const AddressLine2 = styled.p`
	color: #333;
	margin: 0 0 1px 0;
`;

export const AddressLine3 = styled.p`
	color: #333;
	margin: 0 0 4px 0;
`;

export const Hours = styled.p`
	color: #333;
	margin: 16px 0;
`;

export const ContactInfoLine1 = styled.p`
	color: #333;
	margin: 0;
`;

export const ContactInfoLine2 = styled.p`
	color: #333;
	margin: 0;
`;

export const ContactInfoLine3 = styled.p`
	color: #333;
	margin: 0;
`;

export const ModalInfoIcon = styled.img`
	// Styles for the icon
	width: 50px;
	height: 50px;
	margin-right: 16px; // Space between the icon and the text
`;

export const ModalInfoHeaderName = styled.h2`
	// Styles for the header
	font-size: 1em;
	color: #333; // Darker color for the title text
	margin: 0;
	line-height: 1.5;
`;

export const ModalInfoDescription = styled.p`
	// Styles for the description
	/* line-height: 0.25; */
	font-size: 0.9em;
`;

export const ModalInfoDescriptionLine2 = styled.p`
	// Additional styles if needed
	font-size: 0.9em;
	/* color: #666; // Lighter color for the description text */
	margin: 0;
`;
