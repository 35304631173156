// src/components/MobileMenu/styles.js
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const MobileMenuContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
	/* z-index: 200; */

	@media (min-width: 768px) {
		width: 100%;
	}
`;

export const MenuContainer = styled.div`
	background: #e4e9ea;
	width: 100%;
	padding-top: 75px;
	min-height: 70vh;

	@media (min-width: 768px) {
		min-height: 60vh;
		overflow-y: auto;
	}
`;

export const MenuItem = styled.div`
	display: flex;
	align-items: center;
	padding: 13px 8px 13px 16px;
	border-bottom: 1px solid #ccc;
`;

export const Icon = styled.img`
	// Assuming you're using images for icons
	margin-right: 8px;
	width: 36px; // Set the width as needed
	height: 36px; // Set the height as needed
`;

export const StyledLink = styled(Link)`
	text-decoration: none; /* Removes underlining */
	color: black; /* Example color */
	font-family: 'Arial', sans-serif; /* Replace with your desired font-family */

	&:link,
	&:visited,
	&:hover,
	&:active {
		color: black; /* Keeps the link color consistent */
	}
`;

export const Label = styled.span`
	font-size: 16px;
	font-weight: bold;
`;
