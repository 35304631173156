// src/components/MobileMenu/index.js
import React from 'react';
import { MobileMenuContainer, MenuContainer, MenuItem, Icon, Label, StyledLink } from './styles';
import mapsData from '../mapData';
import FooterComponent from '../FooterComponent';

const MobileMenu = () => {


  return (
    <MobileMenuContainer>
      <MenuContainer>
        {mapsData.map(map => (
              <MenuItem key={map.id}>
                <Icon src={`/images/${map.id}-icon.png`} alt={map.id} />
                <Label>
                  <StyledLink to={`/map/${map.id}`} >{map.mapName}</StyledLink>
                </Label>
              </MenuItem>
            ))}
      </MenuContainer>
      <FooterComponent />
    </MobileMenuContainer>
  );
}

export default MobileMenu;

/*
<>
<MenuContainer>
  <MenuItem>
    <Icon src="/images/buildings-icon.png" alt="Buildings" />
    <Label>
    <StyledLink to="/buildings">Buildings</StyledLink>
    </Label>
  </MenuItem>
  <MenuItem>
    <Icon src="/images/parking-icon.png" alt="Parking" />
    <Label>
    <StyledLink to="/parking">Parking</StyledLink>
    </Label>
  </MenuItem>
  <MenuItem>
    <Icon src="/images/bicycle-parking-icon.png" alt="Bicycle Parking" />
    <Label>
    <StyledLink to="/bicycle-parking">Bicycle Parking</StyledLink>
    </Label>
  </MenuItem>
  <MenuItem>
    <Icon src="/images/bicycle-repair-station-icon.png" alt="Bicycle Repair Station" />
    <Label>
    <StyledLink to="/bicycle-repair-station">Bicycle Repair Station</StyledLink>
    </Label>
  </MenuItem>
  <MenuItem>
    <Icon src="/images/accessible-entrance-icon.png" alt="Accessible Enterances" />
    <Label>
    <StyledLink to="/accessible-enterances">Accessible Enterances</StyledLink>
    </Label>
  </MenuItem>
  <MenuItem>
    <Icon src="/images/pedway-icon.png" alt="Pedways" />
    <Label>
    <StyledLink to="/pedways">Pedways</StyledLink>
    </Label>
  </MenuItem>
  <MenuItem>
    <Icon src="/images/washroom-icon.png" alt="washrooms" />
    <Label>
    <StyledLink to="/washrooms">Washrooms</StyledLink>
    </Label>
  </MenuItem>
  <MenuItem>
    <Icon src="/images/water-fountain-icon.png" alt="Water Fountains" />
    <Label>
    <StyledLink to="/water-fountains">Water Fountains</StyledLink>
    </Label>
  </MenuItem>
  <MenuItem>
    <Icon src="/images/elevator-icon.png" alt="Elevators" />
    <Label>
    <StyledLink to="/elevators">Elevators</StyledLink>
    </Label>
  </MenuItem>
</MenuContainer>
  <FooterComponent />
  </>
  */