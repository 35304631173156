import styled from "styled-components";

// export const ModalBackContainer = styled.div`
// 	/* display: flex;
// 	align-items: center;
// 	justify-content: center; */
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	background: rgba(0, 0, 0, 0.6);
// 	z-index: 1000;
// `;

export const ModalBackdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	z-index: 10000;
`;

export const ModalContainer = styled.div`
	position: fixed;
	top: 43%;
	left: 50%;
	/* transform: translate(-50%, -50%) scale(1); // Ensure initial scale is 1 */
	width: 80vw;
	/* max-width: 400px; */
	max-height: 70vh;
	border-radius: 8px;
	overflow: hidden;
	background: white;
	padding: 0;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	z-index: 10001;

	@media (min-width: 768px) {
		width: 20vw;
		max-height: 75vh;
	}
`;

export const ModalHeader = styled.div`
	display: flex;
	flex-direction: column;
`;

export const TopHeader = styled.div`
	background-color: white; // Dark red background for the top tier
	color: white;
	display: flex;
	justify-content: flex-end;
	padding: 2px;
`;

export const CloseButton = styled.button`
	background: none;
	border: none;
	font-size: 24px;
	color: maroon; // Use a deep red color for the title
	cursor: pointer;
`;

export const BottomHeader = styled.div`
	background-color: #000000; // Black background for the bottom tier
	color: white;
	padding: 4px 4px 4px 24px;
	text-align: left;
`;

export const ModalImage = styled.img`
	width: 100%;
	height: auto;
`;

export const TabContainer = styled.div`
	display: flex;
	border-top: 3px solid #800000; // Maroon colored top border
	margin-top: -4px;
`;

export const Tab = styled.button`
	flex: 1;
	padding: 12px 0; // Vertical padding. Adjust as needed
	margin: 0; // Remove margins to remove space between tabs
	border: none;
	background: #551a3d; // inactive tab color
	color: white;
	font-size: 20px;
	font-weight: bold;
	outline: none; // Remove the outline to maintain style on focus
	cursor: pointer;

	// Style for the active tab
	&.active {
		background: white; // Maroon background for the active tab
		color: black; // White text for active tab
	}
`;

export const ModalContent = styled.div`
	padding: 16px;
`;

export const Title = styled.h1`
	font-size: 24px; // Adjust the font size as needed
	margin: 0; // Remove default margin
	color: white; // White text for the title
`;
