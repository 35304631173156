import styled from "styled-components";
import { Facebook } from "@styled-icons/boxicons-logos/Facebook";
import { Twitter } from "@styled-icons/boxicons-logos/Twitter";
import { Instagram } from "@styled-icons/boxicons-logos/Instagram";
import { Linkedin } from "@styled-icons/boxicons-logos/Linkedin";
import { Youtube } from "@styled-icons/boxicons-logos/Youtube";

export const Footer = styled.div`
	background: #551a3d; // Example color, match with your theme
	color: #fff;
	padding: 25px;
	text-align: center;
	height: 23vh;

	@media (min-width: 768px) {
	}
`;

export const SocialMediaIcon = styled.img`
	// Assuming you're using images for icons
	margin: 0 8px;
	width: 24px; // Set the width as needed
	height: 24px; // Set the height as needed
`;

export const StyledFacebookIcon = styled(Facebook)`
	margin: 0 8px;
	width: 24px; // Set the width as needed
	height: 24px; // Set the height as needed
	border: 1px solid red;
	background-color: red;
	border-radius: 50%;
`;
export const StyledXIcon = styled(Twitter)`
	margin: 0 8px;
	width: 24px; // Set the width as needed
	height: 24px; // Set the height as needed
	border: 1px solid red;
	background-color: red;
	border-radius: 50%;
`;
export const StyledInstagramIcon = styled(Instagram)`
	margin: 0 8px;
	width: 24px; // Set the width as needed
	height: 24px; // Set the height as needed
	border: 1px solid red;
	background-color: red;
	border-radius: 50%;
`;

export const StyledLinkedinIcon = styled(Linkedin)`
	margin: 0 8px;
	width: 24px; // Set the width as needed
	height: 24px; // Set the height as needed
	border: 1px solid red;
	background-color: red;
	border-radius: 50%;
`;

export const StyledYoutubeIcon = styled(Youtube)`
	margin: 0 8px;
	width: 24px; // Set the width as needed
	height: 24px; // Set the height as needed
	border: 1px solid red;
	background-color: red;
	border-radius: 50%;
`;

export const FooterText = styled.p`
	line-height: 1.25; // Reduces the space between lines of text
	margin: 0 0 5px 0; // Removes margin above and below the text
	// Add other styles as needed (font size, color, etc.)
`;
