import React from 'react';
import { MapFooterContainer, InstructionText } from './styles';

const MapFooterComponent = () => {
  return (
    <MapFooterContainer>
      <InstructionText>Click the top left menu to explore Building Accessibility Features</InstructionText>
      <InstructionText>Zoom in to Navigate the Map</InstructionText>
    </MapFooterContainer>
  );
};

export default MapFooterComponent;
