import React from "react";
import {
	InfoSection,
	ScrollableContent,
	AmenityItem,
	AmenityIcon,
	AmenityTitle,
	AmenityDescription,
} from "./styles";

const AmenitiesComponent = ({ data }) => {
	// if (!data || !data[0]?.amenitiesComponentData) return null;
	if (!data) return null;

	// const amenitiesData = Object.values(data[0].amenitiesComponentData);
	// const amenitiesData = Object.values(data); // Adjusted to use data directly

	// const amenitiesData = data
	// 	.filter((item) => item.amenitiesComponentData)
	// 	.map((item) => item.amenitiesComponentData);

	// const {
	// 	amenityOne,
	// 	amenityTwo,
	// 	amenityThree,
	// 	amenityFour,
	// 	amenityFive,
	// 	amenitySix,
	// } = data[0].amenitiesComponentData;

	// const amenitiesData = [
	// 	amenityOne,
	// 	amenityTwo,
	// 	amenityThree,
	// 	amenityFour,
	// 	amenityFive,
	// 	amenitySix,
	// ];

	//
	return (
		<InfoSection>
			{data.map((amenity, index) => (
				<AmenityItem key={index}>
					<AmenityIcon
						src={amenity.icon}
						alt={amenity.title}
					/>
					<div>
						<AmenityTitle>{amenity.title}</AmenityTitle>
						<AmenityDescription>
							{amenity.description}
						</AmenityDescription>
					</div>
				</AmenityItem>
			))}
		</InfoSection>
	);
};

export default AmenitiesComponent;
