// index.js
import React from "react";
import { Link } from "react-router-dom";
import { Nav, NavList, NavItem, NavGroup, NavImage } from "./styles";

const NavComponent = (props) => {
	return (
		<Nav>
			<NavGroup>
				<NavList>
					<NavItem>
						<Link to="/">
							<NavImage
								src="/images/Hamburger_1.png"
								alt="Hamburger"
							/>
						</Link>
					</NavItem>
					<NavItem>
						<Link
							to="/map/buildings"
							onClick={() => props.onHomeClick()}
						>
							<NavImage
								src="/images/Home.png"
								alt="Home"
							/>
						</Link>
					</NavItem>
				</NavList>
			</NavGroup>
			<NavList>
				<NavItem>
					<NavImage src="/images/SMU-Logo.png" alt="Logo" />
				</NavItem>
			</NavList>
		</Nav>
	);
};

export default NavComponent;
